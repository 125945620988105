import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Back Squat 4-4-4-4\\@85% 1RM`}</p>
    <p>{`Clean Grip RDL’s 4-4-4-4`}</p>
    <p>{`then,`}</p>
    <p>{`50 Calorie Row`}</p>
    <p>{`40 Step Ups (20/leg, 25/15`}{`#`}{` db’s)`}</p>
    <p>{`30 GHD Situps`}</p>
    <p>{`20 Box Jump Overs (24/20″)`}</p>
    <p>{`10 Back Squats (205/135)`}</p>
    <p>{`20 Box Jump Overs`}</p>
    <p>{`30 GHD Situps`}</p>
    <p>{`40 Step Ups`}</p>
    <p>{`50 Calorie Row`}</p>
    <p>{`For time.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`The CrossFit Open starts February 23rd! Sign up now at:
Games.crossfit.com.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`The next Strength Program starts March 4th. Email Eric at
fallscitystrength\\@gmail for more info.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      